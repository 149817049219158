/* eslint-disable jsx-a11y/alt-text */
import { ListItem, IconButton, List, Avatar, Box } from "@mui/material";
import { Company } from "src/@types/userProfile";
import { ExMenuItem } from "../components/dropdown/Dropdown";
import { EditGray, EditGreen } from "src/assets";
import { AddConnection } from "./AddConnection";
import styled from "@emotion/styled";
import useToggle from "src/hooks/useToggle";
import { EditConnectionPopup } from "../connection-menu/EditConnectionPopup";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

type Props = {
  connections: Array<Company> | [];
  isAdd: boolean;
  onSelectId: (connection: Company) => void;
  itemSelected: Company | undefined;
};

const FormConnectionWrapper = styled(Box)({
  boxShadow: "0px 0px 6px #00000029",
  borderRadius: "0px 0px 3px 3px",
  // #EX3-696: fix bug layout list dropdown
  maxHeight: "320px",
  overflowY: "auto",
  position: "absolute" as "absolute",
  top: "0px",
  left: "100%",
  // #EX3-421: showing all characters
  maxWidth: 600,
  paddingLeft: 2,
  backgroundColor: "white",
  display: "none",
});

const ButtonConnectionWrapper = styled(Box)({
  width: "40px",
  height: "33px",
  // paddingTop: "2px",
});

const ItemConnectionWrapper = styled(Box)({
  fontSize: 14,
  // marginBottom: "-7px",
  // marginTop: "-7px",
  justifyContent: "left",
  // height: 30,
  width: "100%",
});

export const ConnectionList = (props: Props) => {
  const { toggle, onClose, onOpen } = useToggle();
  const [editConnection, setEditConnection] = useState<Company | null>(null);

  if (props.isAdd) {
    return <AddConnection />;
  }

  return (
    <FormConnectionWrapper className="connection-list">
      <List disablePadding>
        {props.connections.map((connection) =>
          connection.company_name === "" ? (
            <ListItem
              key={connection.company_id}
              disableGutters
              style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
              sx={{
                borderBottom: "1px solid #F6EFEF",
              }}
            >
              <ItemConnectionWrapper>
                <ExMenuItem
                  style={{ margin: 0 }}
                  sx={{
                    color: "#6E6E6E",
                    fontSize: "14px",
                    display: "block",
                  }}
                  title={"新しい接続先"}
                  onClick={() => {
                    if (
                      connection.company_id ===
                        props.itemSelected?.company_id &&
                      connection.can_edit_company
                    ) {
                      setEditConnection(connection);
                      onOpen();
                    } else {
                      props.onSelectId(connection);
                    }
                  }}
                  disabled={
                    connection.company_id === props.itemSelected?.company_id &&
                    !connection.can_edit_company
                  }
                >
                  {" "}
                  {"新しい接続先"}{" "}
                </ExMenuItem>
              </ItemConnectionWrapper>
              {/* {connection.can_edit_company && (
                <ButtonConnectionWrapper>
                  {connection.can_edit_company && (
                    <IconButton
                      onClick={() => {
                        setEditConnection(connection);
                        onOpen();
                      }}
                      // disabled={!connection.can_edit_company}
                    >
                      {connection.company_id ===
                        props.itemSelected?.company_id && (
                        <Avatar
                          variant="square"
                          style={{ width: 12, height: 12 }}
                          src={EditGreen}
                        />
                      )}
                      {connection.company_id !==
                        props.itemSelected?.company_id && (
                        <Avatar
                          variant="square"
                          style={{ width: 12, height: 12 }}
                          src={EditGray}
                        />
                      )}
                    </IconButton>
                  )}
                </ButtonConnectionWrapper>
              )} */}
            </ListItem>
          ) : (
            <ListItem
              key={connection.company_id}
              disableGutters
              style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
              sx={{
                borderBottom: "1px solid #F6EFEF",
                backgroundColor:
                  connection.company_id === props.itemSelected?.company_id
                    ? "#085749"
                    : "white",
              }}
              // secondaryAction={
              // }
            >
              <ItemConnectionWrapper>
                <ExMenuItem
                  style={{ margin: 0 }}
                  sx={{
                    color:
                      connection.company_id === props.itemSelected?.company_id
                        ? "#FFFFFF"
                        : "#6E6E6E",
                    fontSize: "14px",
                    display: "block",
                  }}
                  title={connection.company_name}
                  onClick={() => props.onSelectId(connection)}
                >
                  {" "}
                  {connection.company_name}{" "}
                </ExMenuItem>
              </ItemConnectionWrapper>

              {connection.can_edit_company && (
                <ButtonConnectionWrapper>
                  <IconButton
                    onClick={() => {
                      setEditConnection(connection);
                      onOpen();
                    }}
                  >
                    {connection.company_id ===
                      props.itemSelected?.company_id && (
                      // <Avatar
                      //   variant="square"
                      //   style={{ width: 12, height: 12 }}
                      //   src={EditGreen}
                      // />
                      <EditIcon sx={{ fontSize: 18, color: "#FFFFFF" }} />
                    )}
                    {connection.company_id !==
                      props.itemSelected?.company_id && (
                      <Avatar
                        variant="square"
                        style={{ width: 12, height: 12 }}
                        src={EditGray}
                      />
                    )}
                  </IconButton>
                </ButtonConnectionWrapper>
              )}
            </ListItem>
          )
        )}
      </List>
      <EditConnectionPopup
        connection={editConnection}
        open={toggle}
        onClose={onClose}
      />
    </FormConnectionWrapper>
  );
};

import axios from 'axios';
// config
import { API_URL } from 'src/config';
// ----------------------------------------------------------------------

const apiClient = axios.create({
  baseURL: API_URL.BASE_URL + API_URL.VERSION,
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error) || 'Something went wrong')
);

export const customFetcher = (url: string, params: any) => apiClient.get(url, {
  params
}).then(res => res.data);

export default apiClient;
